var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('extrato-detail',{attrs:{"data":_vm.item},on:{"clear":_vm.clear},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('confirmation-delete',{on:{"excluir":_vm.excluir},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}}),_c('material-card',{attrs:{"title":"Posição Financeira"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Pesquisa","placeholder":"Filtro de pesquisa","append-icon":"mdi-magnify","hide-details":"","dense":"","clearable":"","outlined":""},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('v-spacer')],1),_c('v-data-table',{key:_vm.index,attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.filtro},scopedSlots:_vm._u([{key:"item.dt_vencimento",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.dt_vencimento),callback:function ($$v) {_vm.$set(item, "dt_vencimento", $$v)},expression:"item.dt_vencimento"}},[_vm._v(_vm._s(_vm._f("formatDateBRFull")(item.dt_vencimento)))])]}},{key:"item.dt_recebimento",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.dt_recebimento),callback:function ($$v) {_vm.$set(item, "dt_recebimento", $$v)},expression:"item.dt_recebimento"}},[_vm._v(_vm._s(_vm._f("formatDateBRFull")(item.dt_recebimento)))])]}},{key:"item.dt_emissao",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.dt_emissao),callback:function ($$v) {_vm.$set(item, "dt_emissao", $$v)},expression:"item.dt_emissao"}},[_vm._v(_vm._s(_vm._f("formatDateBRFull")(item.dt_emissao)))])]}},{key:"item.extrato",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align-content":"center"}},[_c('v-btn',_vm._g({attrs:{"rounded":"","dense":"true","color":"primary","small":"true","disabled":item.loading},on:{"click":function($event){return _vm.edit(item)}}},_vm.on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-folder-search ")]),_vm._v("Extrato ")],1)],1)]}},{key:"item.boletos",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align-content":"center"}},[(_vm.imprimirBoleto(item))?_c('v-btn',_vm._g({attrs:{"rounded":"","dense":"true","color":"primary","small":"true","loading":_vm.loadingBoleto},on:{"click":function($event){return _vm.DownloadBoleto(item.nr_lancamento)}}},_vm.on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-printer ")]),_vm._v("Gerar Boleto ")],1):_vm._e()],1)]}},{key:"item.recibos",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align-content":"center"}},[(_vm.imprimirRecibo(item))?_c('v-btn',_vm._g({attrs:{"rounded":"","dense":"true","color":"primary","small":"true","loading":_vm.loadingRecibo},on:{"click":function($event){return _vm.DownloadRecibo(item.nr_lancamento)}}},_vm.on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-printer ")]),_vm._v("Imprimir Recibo ")],1):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }