<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :loading="loading"
      persistent
      :fullscreen="$vuetify.breakpoint.name == `xs`"
    >
      <v-card outlined>
        <v-card-title class="primary" flex dense fixed>
          <span class="headline white--text">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="close" right>mdi-close-circle-outline</v-icon>
        </v-card-title>
        <v-row class="row justify-center ma-0">
          <v-btn-toggle
            v-show="PossuiCTE"
            class="justify-center centered"
            dense
          >
            <v-btn :loading="loadingXML" color="primary" @click="DownloadXML">
              <v-icon>mdi-cloud-download-outline</v-icon>Download do CTE(XML)
            </v-btn>
            <v-btn :loading="loadingPDF" color="primary" @click="DownloadPDF">
              <v-icon>mdi-cloud-download-outline</v-icon>Download do CTE(PDF)
            </v-btn>
          </v-btn-toggle>
        </v-row>

        <v-row class="row justify-center ma-0">
          <v-btn-toggle class="justify-center centered" dense>
            <v-btn color="primary" @click="FecharDetalhes">
              <v-icon>mdi-arrow-collapse-all</v-icon>Fechar Detalhes
            </v-btn>
            <v-btn color="primary" @click="ExpandirDetalhes">
              <v-icon>mdi-arrow-expand-all</v-icon>Expandir Detalhes
            </v-btn>
            <v-btn color="primary" @click="ImprimirExtrato">
              <v-icon>mdi-cloud-print-outline</v-icon>Imprimir Extrato
            </v-btn>
          </v-btn-toggle>
        </v-row>
        <div id="testeimpressao">
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="nr"
            expand
            show-expand
            ref="dTable"
            expand-icon="mdi-plus"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table dense fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">NF</th>
                        <th class="text-left">Volume</th>
                        <th class="text-left">Peso</th>
                        <th class="text-left">Frete</th>
                        <th class="text-left">Peso</th>
                        <th class="text-left">Material Volumoso</th>
                        <th class="text-left">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td>{{ item.nf }}</td>
                      <td>{{ item.volume }}</td>
                      <td>{{ item.peso | FormatMoney }}</td>
                      <td>{{ item.frete }}</td>
                      <td>{{ item.peso_vol }}</td>
                      <td>{{ item.taxa }}</td>
                      <td>{{ item.total | FormatMoney }}</td>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:[`item.nr`]="{ item }">
              <v-btn
                rounded
                color="primary"
                small="true"
                v-on="on"
                @click="openPedidoScreen(item.nr)"
                >{{ item.nr }}</v-btn
              >
            </template>

            <template v-slot:[`item.coleta`]="{ item }">
              <v-label v-model="item.coleta" type="string">{{
                item.coleta | formatDateBRFull
              }}</v-label>
            </template>
          </v-data-table>
          <v-spacer class="white"></v-spacer>
          <p class="title text-center white mt-3">Somatório dos Pedidos</p>
          <v-data-table
            :headers="headersSum"
            :items="SumValues"
            ref="tableSUM"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:[`item.somaPeso`]="{ item }">
              <v-label v-model="item.somaPeso">{{
                item.somaPeso | FormatMoney
              }}</v-label>
            </template>
          </v-data-table>
          <v-spacer></v-spacer>
        </div>
        <v-btn block="true" color="primary" @click="close">
          <v-icon left>mdi-close-circle-outline</v-icon>FECHAR
        </v-btn>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from "@/utils/roles.js";
import funcoesPedido from "@/mixins/funcoesPedido.js";
export default {
  data: () => ({
    loading: null,
    loadingXML: null,
    loadingPDF: null,
    valid: null,
    lazy: false,
    PossuiCTE: false,
    headers: [
      { sortable: true, text: "Data", value: "coleta", align: "center" },
      { sortable: true, text: "CTE", value: "cdCcPagador", align: "center" },
      { sortable: true, text: "Pedido", value: "nr", align: "center" },
      { sortable: true, text: "Emitente", value: "emitente", align: "center" },
      {
        sortable: true,
        text: "Destinatário",
        value: "destinatario",
        align: "center",
      },
    ],
    headersSum: [
      { sortable: true, text: "Total", value: "somaPedidos", align: "center" },
      { sortable: true, text: "KM", value: "somaKM", align: "center" },
      { sortable: true, text: "Peso", value: "somaPeso", align: "center" },
      {
        sortable: true,
        text: "Frete(R$)",
        value: "somaValorFrete",
        align: "center",
      },
      {
        sortable: true,
        text: "Peso(R$)",
        value: "somaPesoRS",
        align: "center",
      },
      {
        sortable: true,
        text: "Material Volumoso(R$)",
        value: "somaMaterialVolumosoRS",
        align: "center",
      },
      {
        sortable: true,
        text: "Desconto(R$)",
        value: "somaDesconto",
        align: "center",
      },
      {
        sortable: true,
        text: "Total(R$)",
        value: "somaTotalRS",
        align: "center",
      },
    ],
    old: {},
    SumValues: [],
  }),
  mixins: [funcoesPedido],
  watch: {
    data: {
      deep: true,
      handler() {
        if (this.data.nr_lancamento != undefined) {
          if (this.data.nr_lancamento > 0) {
            this.loading = true;
            this.SumValues = [];
            this.search();
          }
        }
      },
    },
  },
  mounted() {
    this.search();
  },
  computed: {
    title() {
      if (this.data.nr_lancamento != undefined) {
        return "Extrato do lançamento: " + this.data.nr_lancamento;
      }
      return "Extrato Lançamento";
    },
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    required,
    DownloadXML() {
      this.loadingXML = true;
      this.axios({
        url:
          this.$store.state.url_api +
          "DownloadCTE/DownloadXMLNrLancamento/" +
          this.data.nr_lancamento,
        method: "GET", // Worked using POST or PUT. Prefer POST
        responseType: "blob", // important
      }).then((response) => {
        var fileDownload = require("js-file-download");
        fileDownload(response.data, this.data.nr_lancamento + "XML.zip");
        this.loadingXML = false;
      });
    },
    FecharDetalhes() {
      this.$refs.dTable.expanded = [];
    },
    ExpandirDetalhes() {
      this.$refs.dTable.expanded = this.items;
    },
    async ImprimirExtrato() {
      await this.$htmlToPaper("testeimpressao");
    },
    DownloadPDF() {
      this.loadingPDF = true;
      this.axios({
        url:
          this.$store.state.url_api +
          "DownloadCTE/DownloadPDFMergedNrLancamento/" +
          this.data.nr_lancamento,
        method: "GET", // Worked using POST or PUT. Prefer POST
        responseType: "blob", // important
      })
        .then((response) => {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            this.data.nr_lancamento + "MergedPDF.pdf"
          );
          this.loadingPDF = false;
        })
        .catch(() => {
          this.loadingPDF = false;
        });
    },
    search() {
      if (this.data.nr_lancamento != undefined) {
        this.loading = true;
        this.axios
          .get(
            this.$store.state.url_api +
              "MinhaConta/GetMinhaContaExtrato/" +
              this.$store.state.tokenDecoded.claim_innerpessoa +
              "," +
              this.data.nr_lancamento
          )
          .then((r) => {
            this.items = r.data;
            this.SumValues = [];
            this.SumValues.push(this.PreencheSum(r.data));

            this.PossuiCTE = this.ValidaExisteCTE(r.data);
            // this.ExpandirDetalhes();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.data.id) {
          this.update(this.data);
        } else {
          this.insert(this.data);
        }
      }
    },
    insert(item) {
      var context = this;
      this.axios
        .post(this.$store.state.url_api + "/posts/", item)
        .then((r) => {
          context.loading = false;
          context.$emit("save", { item: r.data, isNew: true });
        })
        .catch(() => {
          context.loading = false;
        });
    },
    update(item) {
      var context = this;
      delete item.loading;
      delete item.loading_remove;
      this.axios
        .put(this.$store.state.url_api + "/posts/" + item.id, item)
        .then(() => {
          context.loading = false;
          context.$emit("save", { item: context.data });
          context.dialog = false;
        })
        .catch(() => {
          context.loading = false;
        });
    },
    close() {
      this.data = this.old;
      this.dialog = false;
      this.loading = false;
      this.loadingXML = false;
      this.loadingPDF = false;
      this.$emit("clear");
      this.$refs.form.reset();
    },
    ValidaExisteCTE(obj) {
      var temCTE = false;
      if (obj.length > 0) {
        obj.forEach((e) => {
          if (Number(e.cdCcPagador).toString() != "NaN") {
            if (Number(e.cdCcPagador) > 0) {
              temCTE = true;
            }
          }
        });
      }
      return temCTE;
    },
    PreencheSum(obj) {
      this.itmSM = new Object();
      this.itmSM.somaPedidos = obj.length;
      this.itmSM.somaKM = 0;
      this.itmSM.somaPeso = this.somaPeso(obj);
      this.itmSM.somaValorFrete = this.somaValorFrete(obj);
      this.itmSM.somaPesoRS = this.somaPesoRS(obj);
      this.itmSM.somaMaterialVolumosoRS = this.somaMaterialVolumosoRS(obj);
      this.itmSM.somaDesconto = 0;
      this.itmSM.somaTotalRS = this.somaTotalRS(obj);

      return this.itmSM;
    },
    somaPeso(obj) {
      var sum = 0;
      if (obj.length > 0) {
        obj.forEach((e) => {
          sum += e.peso;
        });
      }
      return sum;
    },
    somaValorFrete(obj) {
      var sum = 0;
      if (obj.length > 0) {
        obj.forEach((e) => {
          sum += e.frete;
        });
      }
      return sum;
    },
    somaPesoRS(obj) {
      var sum = 0;
      if (obj.length > 0) {
        obj.forEach((e) => {
          sum += e.pesoVol;
        });
      }
      return sum;
    },
    somaMaterialVolumosoRS(obj) {
      var sum = 0;
      if (obj.length > 0) {
        obj.forEach((e) => {
          sum += e.taxa;
        });
      }
      return sum;
    },
    somaTotalRS(obj) {
      var sum = 0;
      if (obj.length > 0) {
        obj.forEach((e) => {
          sum += e.total;
        });
      }
      return sum;
    },
  },
  props: {
    value: Boolean,
    data: {},
    index: Number,
    items: {},
    itmSM: {},
  },
};
</script>

<style lang="scss">
@media only screen and (max-device-width: 700px) {
  .v-btn-toggle {
    flex-direction: column;
  }
}
</style>

