<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.name == `xs`"
    >
      <v-card :loading="loading" outlined>
        <v-card-title class="primary" dense>
          <span class="headline white--text">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-icon dark @click="close" right>mdi-close-circle-outline</v-icon>
        </v-card-title>
        <v-card-text>
          <v-subheader class="title">Justifique seu cancelamento</v-subheader>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="itemCancelarScreen.nome"
                  :rules="[required, length_minimum_10]"
                  label="Seu Nome"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="itemCancelarScreen.motivocancelamento"
                  :rules="[required, length_minimum_15]"
                  label="Motivo Cancelamento"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="close" outlined>
            <v-icon left>mdi-close-circle-outline</v-icon>Fechar
          </v-btn>
          <v-btn color="primary" :loading="loading" @click="save">
            <v-icon left>mdi-content-save-outline</v-icon>Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  required,
  email_required,
  length_minimum_5,
  length_minimum_10,
  length_minimum_15,
} from "@/utils/roles.js";
export default {
  data: () => ({
    loading: null,
    valid: null,
    lazy: false,
    old: {},
    itemCancelarScreen: {},
  }),
  computed: {
    title() {
      if (this.pedidoCancelar.cdPedido) {
        return "Cancelar Pedido: " + this.pedidoCancelar.cdPedido;
      }
      return "Cancelar Pedido";
    },
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    required,
    email_required,
    length_minimum_5,
    length_minimum_10,
    length_minimum_15,
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.pedidoCancelar.cdPedido) {
          this.update();
        } else {
          this.loading = false;
        }
      }
    },
    update() {
      this.loading = true;
      var context = this;
      this.axios
        .post(
          this.$store.state.url_api +
            "Pedido/CancelarPedido/" +
            "?idPedido=" +
            this.pedidoCancelar.cdPedido +
            "&idCliente=" +
            this.$store.state.tokenDecoded.claim_innerpessoa +
            "&txtNomeCliente=" +
            this.itemCancelarScreen.nome +
            "&txtExplicacaoCancelamento=" +
            this.itemCancelarScreen.motivocancelamento
        )
        .then((r) => {
          if (r != undefined) {
            context.loading = false;
            this.$emit("save", { dadosCancelamento: this.pedidoCancelar });
          }
        })
        .catch(function (error) {
          context.$snotify.info(error.response.data, "Informação", {
            timeout: 3000,
            showProgressBar: true,
            titleMaxLength: 200,
            bodyMaxLength: 200,
            closeOnClick: true,
            position: "centerCenter",
            preventDuplicates: true,
          });
          context.loading = false;
        });
    },
    close() {
      this.dialogCancelamento = false;
      this.$emit("clear");
      this.$refs.form.reset();
    },
  },
  props: {
    value: Boolean,
    pedidoCancelar: {},
    index: Number,
  },
};
</script>