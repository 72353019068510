var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"role-view","fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('screen-title',{attrs:{"title":"Faturamento Parcial"}}),_c('v-divider',{staticClass:"mb-2"}),_c('v-alert',{staticClass:"ml-4",attrs:{"outlined":"true","dismissible":"true","type":"info"}},[_vm._v(_vm._s(_vm.horarioApuracao)+" ")]),_c('v-spacer'),_c('v-alert',{staticClass:"ml-4 mt-2",attrs:{"outlined":"true","dismissible":"true","type":"warning"}},[_vm._v("Sujeito a alteração no decorrer do dia.")]),_c('v-divider',{staticClass:"mt-2"}),_c('material-card',{attrs:{"title":"Coletas na sua Empresa"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Pesquisa","placeholder":"Filtro de pesquisa","append-icon":"mdi-magnify","hide-details":"","dense":"","clearable":"","outlined":""},model:{value:(_vm.filtroColeta),callback:function ($$v) {_vm.filtroColeta=$$v},expression:"filtroColeta"}})],1),_c('v-spacer')],1),_c('v-data-table',{key:_vm.index,attrs:{"headers":_vm.headersColeta,"items":_vm.itemsColeta,"loading":_vm.loadingColeta,"search":_vm.filtroColeta},scopedSlots:_vm._u([{key:"item.cdPedido",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","small":"true"},on:{"click":function($event){return _vm.openPedidoScreen(item.cdPedido)}}},_vm.on),[_vm._v(_vm._s(item.cdPedido))])]}},{key:"item.dtColeta",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.dtColeta),callback:function ($$v) {_vm.$set(item, "dtColeta", $$v)},expression:"item.dtColeta"}},[_vm._v(_vm._s(_vm._f("formatDateBRFull")(item.dtColeta)))])]}},{key:"item.vlTotal",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.vlTotal),callback:function ($$v) {_vm.$set(item, "vlTotal", $$v)},expression:"item.vlTotal"}},[_vm._v(_vm._s(_vm._f("FormatMoney")(item.vlTotal)))])]}}])})],1),_c('material-card',{attrs:{"title":"Entregas na sua Empresa"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Pesquisa","placeholder":"Filtro de pesquisa","append-icon":"mdi-magnify","hide-details":"","dense":"","clearable":"","outlined":""},model:{value:(_vm.filtroEntrega),callback:function ($$v) {_vm.filtroEntrega=$$v},expression:"filtroEntrega"}})],1),_c('v-spacer')],1),_c('v-data-table',{key:_vm.index,attrs:{"headers":_vm.headersEntrega,"items":_vm.itemsEntrega,"loading":_vm.loadingEntrega,"search":_vm.filtroEntrega},scopedSlots:_vm._u([{key:"item.cdPedido",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g({attrs:{"rounded":"","color":"primary","small":"true"},on:{"click":function($event){return _vm.openPedidoScreen(item.cdPedido)}}},_vm.on),[_vm._v(_vm._s(item.cdPedido))])]}},{key:"item.dtColeta",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.dtColeta),callback:function ($$v) {_vm.$set(item, "dtColeta", $$v)},expression:"item.dtColeta"}},[_vm._v(_vm._s(_vm._f("formatDateBRFull")(item.dtColeta)))])]}},{key:"item.vlTotal",fn:function(ref){
var item = ref.item;
return [_c('v-label',{model:{value:(item.vlTotal),callback:function ($$v) {_vm.$set(item, "vlTotal", $$v)},expression:"item.vlTotal"}},[_vm._v(_vm._s(_vm._f("FormatMoney")(item.vlTotal)))])]}}])})],1),_c('v-spacer'),_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.somaTotal))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }