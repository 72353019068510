<template>
 <div
          class="v-sheet v-sheet--tile theme--dark v-toolbar v-toolbar--flat accent rounded elevation1 blue darken-3"
        >
        <span>
          <h4 class=" title font-weight-light ml-4 mt-4" v-text="title"></h4>
         </span>
         <p class="category font-weight-thin"></p>
        </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },

  computed: {
    
  }
}
</script>