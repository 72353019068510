<template>
  <v-container id="role-view" fill-height fluid grid-list-xl>
    <cancelar-pedido v-model="dialogCancelamento" :pedidoCancelar="itemCancelamento" @save="save_cancelamento" @clear="clear_cancelamento"></cancelar-pedido>
    <v-layout justify-center wrap>
      <v-flex md12>
        <screen-title title="Rastreamento"></screen-title>
        <v-divider class="mb-2" />
        <v-form @submit.prevent="submit">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                :error-messages="validacaoDataIni"
                required
                v-mask="'##/##/####'"
                v-model="DateIniFiltro"
                label="Data Inicial"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                :error-messages="validacaoDataFim"
                required
                v-mask="'##/##/####'"
                v-model="DateFimFiltro"
                label="Data Final"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-btn color="primary" :loading="isLoading" @click="submit">Filtrar</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-divider class="mb-2" />
        <v-spacer />

        <material-card :hidden="!coletaLoaded" title="Coletas na sua Empresa">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filtroColeta"
                label="Pesquisa"
                placeholder="Filtro de pesquisa"
                append-icon="mdi-magnify"
                hide-details
                dense
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-data-table
            :headers="headersColeta"
            :items="itemsColeta"
            :loading="loadingColeta"
            :search="filtroColeta"
            :key="index"
          >
            <template v-slot:[`item.nPedido`]="{ item }">
              <v-row class="ma-n2">
                <v-btn
                  rounded
                  color="primary"
                  small="true"
                  v-on="on"
                  @click="openPedidoScreen(item.cdPedido)"
                >{{item.nPedido}}</v-btn>
              </v-row>
              <v-row class="ma-n2">
                <v-btn
                  rounded
                  color="primary"
                  small="true"
                  v-on="on"
                  v-if="cancelarVisible(item)"
                  @click="cancelarPedido(item)"
                >Cancelar Pedido</v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.dataEntrega`]="{ item }">
              <v-label v-model="item.dataEntrega">{{item.dataEntrega | formatDateBRFull}}</v-label>
            </template>
            <template v-slot:[`item.dtRepasse`]="{ item }">
              <v-label v-model="item.dtRepasse">{{item.dtRepasse | formatDateBRFull}}</v-label>
            </template>
          </v-data-table>
        </material-card>

        <material-card :hidden="!entregaLoaded" title="Entregas na sua Empresa">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filtroEntrega"
                label="Pesquisa"
                placeholder="Filtro de pesquisa"
                append-icon="mdi-magnify"
                hide-details
                dense
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-data-table
            :headers="headersEntrega"
            :items="itemsEntrega"
            :loading="loadingEntrega"
            :search="filtroEntrega"
            :key="index"
          >
            <template v-slot:[`item.nPedido`]="{ item }">
              <v-row class="ma-n2">
                <v-btn
                  rounded
                  color="primary"
                  small="true"
                  v-on="on"
                  @click="openPedidoScreen(item.cdPedido)"
                >{{item.nPedido}}</v-btn>
              </v-row>
              <v-row class="ma-n2">
                <v-btn
                  rounded
                  color="primary"
                  small="true"
                  v-on="on"
                  v-if="cancelarVisible(item)"
                  @click="cancelarPedido(item)"
                >Cancelar Pedido</v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.dataEntrega`]="{ item }">
              <v-label v-model="item.dataEntrega">{{item.dataEntrega | formatDateBRFull}}</v-label>
            </template>
            <template v-slot:[`item.dtRepasse`]="{ item }">
              <v-label v-model="item.dtRepasse">{{item.dtRepasse | formatDateBRFull}}</v-label>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MaterialCard from "@/components/material/Card.vue";
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import ScreenTitle from "@/components/visual/ScreenTitle.vue";
import funcoesPedido from "@/mixins/funcoesPedido.js";
import CancelarPedido from "@/views/pedido/CancelarPedido.vue";

export default {
  data: () => ({
    dialog: null,
    loading: null,
    loadingCancelamento: null,
    DateIniFiltro: null,
    DateFimFiltro: null,
    loadingColeta: null,
    loadingEntrega: null,
    coletaLoaded: false,
    entregaLoaded: false,
    confirmDelete: null,
    show_created_time: null,
    created_time: null,
    dialogCancelamento: null,
    filtroColeta: "",
    filtroEntrega: "",
    itemCancelamento: [],
    itemColeta: [],
    itemEntrega: [],
    headersColeta: [
      {
        sortable: true,
        text: "Pedido",
        value: "nPedido",
        align: "right"
      },
      { sortable: true, text: "CT-E", value: "cdCCPagador", align: "center" },
      {
        sortable: true,
        text: "Destinatário",
        value: "dsNome",
        align: "center"
      },
      {
        sortable: true,
        text: "Solicitante",
        value: "nmSolicitante",
        align: "center"
      },
      {
        sortable: true,
        text: "Data da Coleta",
        value: "dtRepasse",
        align: "right"
      },
      { sortable: true, text: "Nr. NF", value: "nrNF", align: "center" },
      {
        sortable: false,
        text: "Data de Entrega",
        value: "dataEntrega",
        align: "center"
      },
      {
        sortable: true,
        text: "Entregue Para",
        value: "por",
        align: "center"
      }
    ],
    headersEntrega: [
      {
        sortable: true,
        text: "Pedido",
        value: "nPedido",
        align: "right"
      },
      { sortable: true, text: "CT-E", value: "cdCCPagador", align: "center" },
      {
        sortable: true,
        text: "Destinatário",
        value: "dsNome",
        align: "center"
      },
      {
        sortable: true,
        text: "Solicitante",
        value: "nmSolicitante",
        align: "center"
      },
      {
        sortable: true,
        text: "Data da Coleta",
        value: "dtRepasse",
        align: "right"
      },
      { sortable: true, text: "Nr. NF", value: "nrNF", align: "center" },
      {
        sortable: false,
        text: "Data de Entrega",
        value: "dataEntrega",
        align: "center"
      },
      {
        sortable: true,
        text: "Entregue Para",
        value: "por",
        align: "center"
      }
    ],
    item: {},
    index: 0
  }),
  validations: {
    DateIniFiltro: { required },
    DateFimFiltro: { required }
  },
  mixins: [funcoesPedido],
  mounted() {
    this.DateIniFiltro = this.DataIniInicial();
    this.DateFimFiltro = this.$options.filters.formatDateBRMin(new Date());
    this.submit();
  },
  components: {
    MaterialCard,
    ScreenTitle,
    CancelarPedido
  },
  computed: {
    ...mapState("post", ["filter", "posts"]),
    validacaoDataIni() {
      const errors = [];
      if (!this.$v.DateIniFiltro.$dirty) return errors;
      if (this.$options.filters.formatDateBRMin(this.DateIniFiltro) == "") {
        !this.$v.DateIniFiltro.required &&
          errors.push("Data Vazia ou Inválida!");
      }
      return errors;
    },
    validacaoDataFim() {
      const errors = [];
      if (!this.$v.DateFimFiltro.$dirty) return errors;
      if (this.$options.filters.formatDateBRMin(this.DateFimFiltro) == "") {
        !this.$v.DateFimFiltro.required &&
          errors.push("Data Vazia ou Inválida!");
      }
      return errors;
    },
    isLoading() {
      var isloading = false;
      if (this.loadingColeta || this.loadingEntrega) {
        isloading = true;
      } else if (this.coletaLoaded && this.entregaLoaded) {
        isloading = false;
      }

      return isloading;
    },
    filtro: {
      get() {
        return this.filter;
      },
      set(value) {
        this.SET_FILTER(value);
      }
    },
    filtroColeta: {
      get() {
        return this.filtroColeta;
      },
      set(value) {
        this.filtroColeta = value;
      }
    },
    filtroEntrega: {
      get() {
        return this.filtroEntrega;
      },
      set(value) {
        this.filtroEntrega = value;
      }
    },
    items: {
      get() {
        return this.posts;
      },
      set(value) {
        this.SET_POSTS(value);
      }
    },
    itemsColeta: {
      get() {
        return this.itemColeta;
      },
      set(value) {
        this.itemColeta = value;
      }
    },
    itemsEntrega: {
      get() {
        return this.itemEntrega;
      },
      set(value) {
        this.itemEntrega = value;
      }
    }
  },
  methods: {
    ...mapMutations("post", ["SET_FILTER", "SET_POSTS"]),
    DataFimInicial() {
      var date = new Date();
      date.setDate(date.getDate() + 15);
      return this.$options.filters.formatDateBRMin(date);
    },
     DataIniInicial() {
      var date = new Date();
      date.setDate(date.getDate() -30 );
      return this.$options.filters.formatDateBRMin(date);
    },
    cancelarPedido(item) {
      this.itemCancelamento = Object.assign({}, item);
      this.dialogCancelamento = true;
    },
    cancelarVisible(item) {
      return item.dsSituacao == "ABERTO";
    },save_cancelamento({dadosCancelamento}){
        this.dialogCancelamento = false;
        var arrayFound = {};
        var indexColeta = 0;
         this.itemColeta.forEach(function (c) { 
         if (c.cdPedido == dadosCancelamento.cdPedido){arrayFound = c; return;}
         indexColeta++; 
        })

        if(arrayFound != {}){
          arrayFound.nPedido = arrayFound.cdPedido + " (Cancelado)";
          arrayFound.dsSituacao = "CANCELADO";
          this.itemColeta[indexColeta] = Object.assign({}, arrayFound);
          return;
        }

        var indexEntrega = 0;
         this.itemsEntrega.forEach(function (c) { 
          if (c.cdPedido == dadosCancelamento.cdPedido){arrayFound = c;return;}
          indexEntrega++;
        })

        if(arrayFound != {}){
          arrayFound.nPedido = arrayFound.cdPedido + " (Cancelado)";
          arrayFound.dsSituacao = "CANCELADO";
          this.itemEntrega[indexEntrega] = Object.assign({}, arrayFound);
          return;
        }

        this.itemCancelamento = {};
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.search();
      } else {
        this.itemsColeta = [];
        this.itemsEntrega = [];
        this.coletaLoaded = false;
        this.entregaLoaded = false;
      }
    },
    search() {
      this.loading = true;
      this.loadingColeta = true;
      this.coletaLoaded = false;
      this.axios
        .get(
          this.$store.state.url_api +
            "MinhaConta/GetMinhaContaRastreamentoColetas/" +
            this.$store.state.tokenDecoded.claim_innerpessoa +
            "," +
            this.moment(this.DateIniFiltro, "DD-MM-YYYY").format("YYYY-MM-DD") +
            "," +
            this.moment(this.DateFimFiltro, "DD-MM-YYYY").format("YYYY-MM-DD")
        , { headers: {'Access-Control-Allow-Origin': '*'} })
        .then(r => {
          this.itemsColeta = r.data;
          this.loadingColeta = false;
          this.coletaLoaded = true;
        })
        .catch(() => {     
          this.itemsColeta = [];    
          this.coletaLoaded = true;
          this.loadingColeta = false;
        });

      this.loadingEntrega = true;
      this.entregaLoaded = false;
      this.axios
        .get(
          this.$store.state.url_api +
            "MinhaConta/GetMinhaContaRastreamentoEntregas/" +
            this.$store.state.tokenDecoded.claim_innerpessoa +
            "," +
            this.moment(this.DateIniFiltro, "DD-MM-YYYY").format("YYYY-MM-DD") +
            "," +
            this.moment(this.DateFimFiltro, "DD-MM-YYYY").format("YYYY-MM-DD")
        )
        .then(r => {
          this.itemsEntrega = r.data;
          this.loadingEntrega = false;
          this.entregaLoaded = true;
        })
        .catch(() => {
          this.itemsEntrega = [];
          this.loadingEntrega = false;
          this.entregaLoaded = true;
        });
      this.loading = false;
    },
    clear_cancelamento() {
      this.itemCancelamento = {}
      this.dialogCancelamento = false;
    }
  }
};
</script>