<template>
  <v-container fill fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <extrato-detail
          v-model="dialog"
          :data="item"
          @clear="clear"
        ></extrato-detail>
        <confirmation-delete
          v-model="confirmDelete"
          @excluir="excluir"
        ></confirmation-delete>
        <material-card title="Posição Financeira">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filtro"
                label="Pesquisa"
                placeholder="Filtro de pesquisa"
                append-icon="mdi-magnify"
                hide-details
                dense
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="dialog = true" style="margin-right: 1%" outlined>
                    <v-icon left>mdi-plus-circle-outline</v-icon>NOVO POST
            </v-btn>-->
          </v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="filtro"
            :key="index"
          >
            <template v-slot:[`item.dt_vencimento`]="{ item }">
              <v-label v-model="item.dt_vencimento">{{
                item.dt_vencimento | formatDateBRFull
              }}</v-label>
            </template>
            <template v-slot:[`item.dt_recebimento`]="{ item }">
              <v-label v-model="item.dt_recebimento">{{
                item.dt_recebimento | formatDateBRFull
              }}</v-label>
            </template>
            <template v-slot:[`item.dt_emissao`]="{ item }">
              <v-label v-model="item.dt_emissao">{{
                item.dt_emissao | formatDateBRFull
              }}</v-label>
            </template>
            <template v-slot:[`item.extrato`]="{ item }">
              <v-row align-content="center">
                <v-btn
                  rounded
                  dense="true"
                  color="primary"
                  small="true"
                  :disabled="item.loading"
                  v-on="on"
                  @click="edit(item)"
                  ><v-icon left dark> mdi-folder-search </v-icon>Extrato
                </v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.boletos`]="{ item }">
              <v-row align-content="center">
                <v-btn
                  rounded
                  dense="true"
                  color="primary"
                  small="true"
                  v-on="on"
                  v-if="imprimirBoleto(item)"
                  :loading="loadingBoleto"
                  @click="DownloadBoleto(item.nr_lancamento)"
                  ><v-icon left dark> mdi-printer </v-icon>Gerar Boleto
                </v-btn>
              </v-row>
            </template>
            <template v-slot:[`item.recibos`]="{ item }">
              <v-row align-content="center">
                <v-btn
                  rounded
                  dense="true"
                  color="primary"
                  small="true"
                  v-on="on"
                  v-if="imprimirRecibo(item)"
                  :loading="loadingRecibo"
                  @click="DownloadRecibo(item.nr_lancamento)"
                  ><v-icon left dark> mdi-printer </v-icon>Imprimir Recibo
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MaterialCard from "@/components/material/Card.vue";
import ConfirmationDelete from "@/components/material/ConfirmationDelete.vue";
import ExtratoDetail from "@/views/faturamento/ExtratoDetail.vue";
import funcoesPedido from "@/mixins/funcoesPedido.js";
import { mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    dialog: null,
    loading: null,
    confirmDelete: null,
    loadingBoleto: null,
    loadingRecibo: null,
    headers: [
      {
        sortable: true,
        text: "Nr Lançamento",
        value: "nr_lancamento",
        align: "right",
      },
      { sortable: true, text: "Emissão", value: "dt_emissao", align: "center" },
      {
        sortable: true,
        text: "Vencimento",
        value: "dt_vencimento",
        align: "center",
      },
      {
        sortable: true,
        text: "Data Pagamento",
        value: "dt_recebimento",
        align: "center",
      },
      { sortable: true, text: "Valor", value: "vl_emissao", align: "right" },
      { sortable: true, text: "Extrato ", value: "extrato", align: "left" },
      {
        sortable: false,
        text: "Boletos em Aberto",
        value: "boletos",
        align: "left",
      },
      { sortable: false, text: "Recibo", value: "recibos", align: "left" },
    ],
    item: {},
    index: 0,
  }),
  mixins: [funcoesPedido],
  mounted() {
    this.search();
  },
  components: {
    MaterialCard,
    ConfirmationDelete,
    ExtratoDetail,
  },
  computed: {
    ...mapState("post", ["filter", "posts"]),
    filtro: {
      get() {
        return this.filter;
      },
      set(value) {
        this.SET_FILTER(value);
      },
    },
    items: {
      get() {
        return this.posts;
      },
      set(value) {
        this.SET_POSTS(value);
      },
    },
    somaPedidos: function () {
      return this.item.length;
    },
    somaKM: function () {
      return 0;
    },
    somaPeso: function () {
      var sum = 0;
      if (this.item.length > 0) {
        this.item.forEach((e) => {
          sum += e.peso;
        });
      }
      return sum;
    },
    somaValorFrete: function () {
      var sum = 0;
      if (this.item.length > 0) {
        this.item.forEach((e) => {
          sum += e.frete;
        });
      }
      return sum;
    },
    somaPesoRS: function () {
      var sum = 0;
      if (this.item.length > 0) {
        this.item.forEach((e) => {
          sum += e.pesoVol;
        });
      }
      return sum;
    },
    somaMaterialVolumosoRS: function () {
      var sum = 0;
      if (this.item.length > 0) {
        this.item.forEach((e) => {
          sum += e.taxa;
        });
      }
      return sum;
    },
    somaDesconto: function () {
      return 0;
    },
    somaTotalRS: function () {
      var sum = 0;
      if (this.item.length > 0) {
        this.item.forEach((e) => {
          sum += e.total;
        });
      }
      return sum;
    },
  },
  methods: {
    ...mapMutations("post", ["SET_FILTER", "SET_POSTS"]),
    imprimirBoleto(item) {
      return item.dt_recebimento == undefined && item.avista != "S";
    },
    imprimirRecibo(item) {
      return item.dt_recebimento != undefined;
    },
    DownloadRecibo(nr_lancamento) {
      this.loadingRecibo = true;
      this.axios({
        url:
          this.$store.state.url_api +
          "Recibo/GerarReciboPDF/" +
          nr_lancamento +"," + this.$store.state.tokenDecoded.claim_innerpessoa
          ,
        method: "GET", // Worked using POST or PUT. Prefer POST
        responseType: "blob", // responseType: "blob", // important
        timeout: 60 * 2 * 1000,
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Recibo.PDF");
          document.body.appendChild(link);
          link.click();
          this.loadingRecibo = false;
        })
        .catch(() => {
          this.loadingRecibo = false;
        });
    },
    DownloadBoleto(nr_lancamento) {
      this.loadingBoleto = true;
      this.axios({
        url:
          this.$store.state.url_api +
          "Boleto/DownloadBoletoLancamento/" +
          nr_lancamento,
        method: "GET", // Worked using POST or PUT. Prefer POST
        responseType: "blob", // responseType: "blob", // important
        timeout: 60 * 2 * 1000,
      })
        .then((response) => {
          /*const content = "application/pdf";
        var fileDownload = require("js-file-download");
        fileDownload(response.data, nr_lancamento + "Boleto.PDF",content);*/
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Boleto.PDF");
          document.body.appendChild(link);
          link.click();
          this.loadingBoleto = false;
        })
        .catch(() => {
          this.loadingBoleto = false;
        });
    },
    search() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.url_api +
            "MinhaConta/GetMinhaContaPosicao/" +
            this.$store.state.tokenDecoded.claim_innerpessoa
        )
        .then((r) => {
          this.items = r.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.dialog = true;
    },
    remove(item) {
      this.item = item;
      this.confirmDelete = true;
    },
    save({ item, isNew }) {
      if (isNew) {
        this.items.push(item);
      } else {
        var index = 0;
        this.items.forEach(function (c) {
          if (c.id == item.id) return;
          index++;
        });
        this.items[index] = Object.assign({}, item);
        this.index += 1;
      }
      this.dialog = false;
      this.item = {};
    },
    excluir() {
      this.confirmDelete = false;
      var context = this;
      var item = this.items.filter((i) => i.id == context.item.id)[0];
      item.loading_remove = true;
      this.axios
        .delete(this.$store.state.url_api + "/posts/" + item.id)
        .then(() => {
          item.loading_remove = false;
          var index = 0;
          context.items.forEach(({ id }) => {
            if (id == item.id) {
              context.items.splice(index, 1);
              return;
            }
            index++;
          });
        })
        .catch(() => {
          item.loading_remove = false;
        });
      this.item = {};
    },
    clear() {
      this.item = {};
    },
  },
};
</script>