export default {
    methods: {
        openPedidoScreen(nrPedido) {
            //var win = window.open("http://www4.disktenha.com.br/romaneio/?id=" + nrPedido, "Detalhes Pedido", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=500");
            //win.focus();
            var iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="https://www.disktenha.com.br/romaneio/?id=' + nrPedido +'" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></html></body>';
            var win = window.open("", "Detalhes", "width=750,height=480");
            win.document.write(iframe);
        },
        openBoletoScreen(nrLancamento) {
            //desativado
            //var win = window.open("http://www4.disktenha.com.br/romaneio/?id=" + nrPedido, "Detalhes Pedido", "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=500");
            //win.focus();
            var iframe = '<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="https://www.disktenha.com.br/minhaconta/boleto/?c=' + nrLancamento +'" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></html></body>';
            var win = window.open("", "Detalhes", "width=750,height=480");
            win.document.write(iframe);
        }
    }

};