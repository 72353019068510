<template>
    <v-row justify="center">
        <v-dialog v-model="open" persistent width="500">
            <v-card>
                <v-card-title class="headline font-weight-bold" primary-title>
                Confirmação Requerida
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <span class="title font-weight-bold">Deseja excluir o registro?</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="error" @click="open = false" outlined>Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" depressed @click="$emit('excluir')">Excluir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        computed: {
            open: {
                get() { return this.value },
                set(v) { this.$emit('input', v) }
            }
        },
        props: {
            value: Boolean
        }
    }
</script>