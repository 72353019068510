<template>
  <v-container id="role-view" fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <screen-title title="Faturamento Parcial"> </screen-title>
        <v-divider class="mb-2"/>
        <v-alert outlined="true" dismissible="true" type="info" class="ml-4">{{horarioApuracao}}
        </v-alert>
        <v-spacer />
        <v-alert outlined="true" dismissible="true" type="warning" class="ml-4 mt-2">Sujeito a alteração no decorrer do dia.</v-alert>
        <v-divider class="mt-2"/>
        <material-card  title="Coletas na sua Empresa">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filtroColeta"
                label="Pesquisa"
                placeholder="Filtro de pesquisa"
                append-icon="mdi-magnify"
                hide-details
                dense
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-data-table
            :headers="headersColeta"
            :items="itemsColeta"
            :loading="loadingColeta"
            :search="filtroColeta"
            :key="index"
          >          
          <template v-slot:item.cdPedido="{ item }">
              <v-btn
                rounded
                color="primary"
                small="true"
                v-on="on"
                @click="openPedidoScreen(item.cdPedido)"
              >{{item.cdPedido}}</v-btn>
            </template>
            <template v-slot:item.dtColeta="{ item }">
              <v-label v-model="item.dtColeta">{{item.dtColeta | formatDateBRFull}}</v-label>
            </template>
            <template v-slot:item.vlTotal="{ item }">
              <v-label v-model="item.vlTotal">{{item.vlTotal | FormatMoney}}</v-label>
            </template>
            
          </v-data-table>
        </material-card>
        <material-card  title="Entregas na sua Empresa">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="filtroEntrega"
                label="Pesquisa"
                placeholder="Filtro de pesquisa"
                append-icon="mdi-magnify"
                hide-details
                dense
                clearable
                outlined
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <v-data-table
            :headers="headersEntrega"
            :items="itemsEntrega"
            :loading="loadingEntrega"
            :search="filtroEntrega"
            :key="index"
          >
             <template v-slot:item.cdPedido="{ item }">
              <v-btn
                rounded
                color="primary"
                small="true"
                v-on="on"
                @click="openPedidoScreen(item.cdPedido)"
              >{{item.cdPedido}}</v-btn>
            </template>
            <template v-slot:item.dtColeta="{ item }">
              <v-label v-model="item.dtColeta">{{item.dtColeta | formatDateBRFull}}</v-label>
            </template>
            <template v-slot:item.vlTotal="{ item }">
              <v-label v-model="item.vlTotal">{{item.vlTotal | FormatMoney}}</v-label>
            </template>
          </v-data-table>
        </material-card>
        <v-spacer />
        <v-chip color="primary">{{somaTotal}}</v-chip>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import MaterialCard from "@/components/material/Card.vue";
import { mapState, mapMutations } from "vuex";
import ScreenTitle from "@/components/visual/ScreenTitle.vue";
import  funcoesPedido from '@/mixins/funcoesPedido.js';

export default {
  data: () => ({
    dialog: null,
    loading: null,
    loadingColeta: null,
    loadingEntrega: null,
    coletaLoaded: null,
    entregaLoaded: null,
    confirmDelete: null,
    filtroColeta: "",
    filtroEntrega: "",
    itemColeta: [],
    itemEntrega: [],
    headersEntrega: [
      {
        sortable: true,
        text: "Pedido",
        value: "cdPedido",
        align: "right"
      },
      { sortable: true, text: "CT-E", value: "cdCCPagador", align: "center" },
      {
        sortable: true,
        text: "Emitente",
        value: "dsNome",
        align: "center"
      },
      {
        sortable: true,
        text: "Solicitante",
        value: "nmSolicitante",
        align: "center"
      },
      {
        sortable: true,
        text: "Data da Coleta",
        value: "dtColeta",
        align: "right"
      },
      { sortable: true, text: "Nr. NF", value: "nrNf", align: "center" },
      {
        sortable: false,
        text: "Valor Total",
        value: "vlTotal",
        align: "center"
      }
    ],
    headersColeta: [
      {
        sortable: true,
        text: "Pedido",
        value: "cdPedido",
        align: "right"
      },
      { sortable: true, text: "CT-E", value: "cdCCPagador", align: "center" },
      {
        sortable: true,
        text: "Destinatário",
        value: "dsNome",
        align: "center"
      },
      {
        sortable: true,
        text: "Solicitante",
        value: "nmSolicitante",
        align: "center"
      },
      {
        sortable: true,
        text: "Data da Coleta",
        value: "dtColeta",
        align: "right"
      },
      { sortable: true, text: "Nr. NF", value: "nrNf", align: "center" },
      {
        sortable: false,
        text: "Valor Total",
        value: "vlTotal",
        align: "center"
      }
    ],
    item: {},
    index: 0
  }),
  mounted() {
    this.search();
  },
  mixins:[funcoesPedido],
  components: {
    MaterialCard,ScreenTitle
  },
  computed: {
    ...mapState("post", ["filter", "posts"]),
    filtro: {
      get() {
        return this.filter;
      },
      set(value) {
        this.SET_FILTER(value);
      }
    },
    filtroColeta: {
      get() {
        return this.filtroColeta;
      },
      set(value) {
        this.filtroColeta = value;
      }
    },
    filtroEntrega: {
      get() {
        return this.filtroEntrega;
      },
      set(value) {
        this.filtroEntrega = value;
      }
    },
    items: {
      get() {
        return this.posts;
      },
      set(value) {
        this.SET_POSTS(value);
      }
    },
    itemsColeta: {
      get() {
        return this.itemColeta;
      },
      set(value) {
        this.itemColeta = value;
      }
    },
    itemsEntrega: {
      get() {
        return this.itemEntrega;
      },
      set(value) {
        this.itemEntrega = value;
      }
    },
    somaTotal: function() {
      var soma = 0;
      if (this.entregaLoaded && this.coletaLoaded) {
        soma =
          this.itemsColeta.reduce((a, { vlTotal }) => a + vlTotal, 0) +
          this.itemsEntrega.reduce((a, { vlTotal }) => a + vlTotal, 0);
      }
      return "Valor Total: " + this.$options.filters.FormatMoney(soma);
    },
    horarioApuracao: function(){
      var strInicio = 'Período da Apuração:';
      var strFinal = strInicio;
      var currentdate = new Date(); 
      var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + "  "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
      strFinal +=datetime ;

      return strFinal;
    }
  },
  methods: {
    ...mapMutations("post", ["SET_FILTER", "SET_POSTS"]),
    search() {
      this.loadingColeta = true;
      this.axios
        .get(
          this.$store.state.url_api +
            "MinhaConta/GetMinhaContaParcialEmitente/"+this.$store.state.tokenDecoded.claim_innerpessoa
        )
        .then(r => {
          this.itemsColeta = r.data;
          this.loadingColeta = false;
          this.coletaLoaded = true;
        })
        .catch(() => {
          this.coletaLoaded = true;
          this.loadingColeta = false;
        });

      this.loadingEntrega = true;
      this.axios
        .get(
          this.$store.state.url_api +
            "MinhaConta/GetMinhaContaParcialDestinatario/" + this.$store.state.tokenDecoded.claim_innerpessoa
        )
        .then(r => {
          this.itemsEntrega = r.data;
          this.loadingEntrega = false;
          this.entregaLoaded = true;
        })
        .catch(() => {
          this.loadingEntrega = false;
          this.entregaLoaded = true;
        });
    },
    edit(item) {
      this.item = Object.assign({}, item);
      this.dialog = true;
    },
    remove(item) {
      this.item = item;
      this.confirmDelete = true;
    },
    save({ item, isNew }) {
      if (isNew) {
        this.items.push(item);
      } else {
        var index = 0;
        this.items.forEach(function(c) {
          if (c.id == item.id) return;
          index++;
        });
        this.items[index] = Object.assign({}, item);
        this.index += 1;
      }
      this.dialog = false;
      this.item = {};
    },
    excluir() {
      this.confirmDelete = false;
      var context = this;
      var item = this.items.filter(i => i.id == context.item.id)[0];
      item.loading_remove = true;
      this.axios
        .delete(this.$store.state.url_api + "/posts/" + item.id)
        .then(() => {
          item.loading_remove = false;
          var index = 0;
          context.items.forEach(({ id }) => {
            if (id == item.id) {
              context.items.splice(index, 1);
              return;
            }
            index++;
          });
        })
        .catch(() => {
          item.loading_remove = false;
        });
      this.item = {};
    },
    clear() {
      this.item = {};
    }
  }
};
</script>